@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
@font-face {
    font-family: Montserrat-Light;
    src: url(/static/media/Montserrat-Light.02afb26f.ttf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(/static/media/Montserrat-Medium.e2d60bc4.ttf);
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/static/media/Montserrat-SemiBold.fa8441f3.ttf);
}

.container{
    max-width: 1280px;
}

p{
    margin-top: 1rem;
}

.header-logo {
    width: 200px !important;
}

.accordion-title-text {
    font-size: .8571428571rem;
    color: #050033;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
}

h2{
    font-weight: 600;
    font-size: 2.1rem;
    color: #050033;
}

.h5, h5 {
    font-size: 1.4285714286rem;
    color: #050033;
    font-weight: 600;
}

h4.benefits-header,
button {
    font-family: Montserrat-Medium,"Verdana",sans-serif;
    color: #050033;
    font-weight: 600;
    font-size: 1.7689rem;
}

.email-check-input {
    display: block;
    margin: 0 auto;
    width: 350px;
    text-align: center;
    font-size: 1rem;
}

.validation-error{
    border: 1px solid #dc3545;
}

.terms_conditions a{
    text-decoration: underline;
    color: #050033;
    font-weight: 600;
}

.terms_conditions p{
    margin: 5px 0;
}

.terms_conditions .title{
    margin-bottom: 20px;
    font-weight: 700;
    color: #74758c;
    font-size: 1.1rem;
}

.hotelSales-wrapper {
    margin-bottom: 10rem;
}

input {
    background-color: #fff;
    border: 1px solid #e6e7e8;
    color: #000;
    padding: 15px 20px;
    line-height: 1.3;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
    transition: color .2s .15s,box-shadow .2s;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    border-radius: 2px;
}

.email-check-input-submit {
    display: block;
    margin: 10px auto 0;
    width: 350px;
    font-weight: 600;
}

.button.theme-primary {
    background: #050033;
    border: 3px solid #050033;
    border-radius: 3px;
    color: #fff;
    padding: 15px 20px;
}

p,
div {
    font-family: Montserrat-Light,"Verdana",sans-serif;
    color: #74758c;
    font-size: 1rem;
}

sup,
.disclaimer {
    color: #FF0000;
}

button.btn {
    border: #050033;
    text-transform: uppercase;
    font-size: .875rem;
}

button.btn-primary{
    background: #ec6527;
    border: 3px solid #ec6527;
    border-radius: 3px;
    color: #fff;
}

button.btn-primary:hover {
    color: #fff;
    background: #18a09f;
    border: 3px solid #18a09f;
}

.button.theme-primary .icon {
    fill: #18a09f;
}

.button.theme-primary--alternate .icon, .button.theme-primary:hover .icon {
    fill: #050033;
}


button .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: #f5ac8a;
    border: 3px solid #f5ac8a;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 #fd7e1494;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #f5ac8a;
    border-color: #f5ac8a;
    box-shadow: 0 0 0 0 #fd7e1494;
}

button.btn-secondary {
    background: #fff;
    border: 3px solid #ec6527;
    border-radius: 3px;
    color: #ec6527;
}

button.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: #ec6527;
    border: 3px solid #ec6527;
}

.banner-dbs {
    width: 100%;
    height: 25vw;
    background-image: url(/static/media/DBS_vantage_card_banner_1650x410_v3.474c62ab.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
}

.banner-subaru {
    width: 100%;
    height: 30vw;
    background-image: url(/static/media/Subaru_banner_1650x1101.dd839bda.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}
.banner-scb {
    width: 100%;
    height: 30vw;
    background-image: url(/static/media/SC_1650x1101.c3bcea54.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 38%;
}
.banner-amex {
    width: 100%;
    height: 30vw;
    background-image: url(/static/media/AMEX_SG_Pullman.0ac25a9a.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 46%;
}

.partners-card {
    margin-top: -35px;
}

/**
*     START Accordion Styles
**/
.card {
    margin-bottom: 0;
}
.benefits-header-container, .benefits-container .card {
    border-bottom: 1px solid #e6e7e8 !important;
    box-shadow: none;
}

.benefits-header-container, .benefits-container .card:first-child { 
    border-top: 1px solid #e6e7e8 !important;
    box-shadow: none;
} 

.benefits .card {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #CCCCCC !important;
    border-radius: 0;
    cursor: pointer;
}

.benefits .card h5 {
    text-align: left;
    padding: 0;
}

.benefits .card h5 button {
    padding: 0;
    color: #000000;
    text-decoration: none;
    text-align: left;
}

.benefits .card-header {
    padding: 20px 0;
    border-bottom: none;
    background-color: transparent;
}

.benefits .card-header:hover {
    cursor: pointer;
}

.benefits .card-body {
    padding: 10px 15px;
}

/**
  *     END Accordion Styles
  */

#hotelNameForm {
    height: 300px;
    overflow-y: auto;
}

.error {
    color: #FF0000;
    font-size: 12px;
}

.hotelSearchModalResults {
    height: 350px; 
    overflow-y: auto;
    overflow-x: hidden;
}

.hotelSearchModalResults .table tr{
    border: none;
}

.hotelSearchModalResults .table {
    color: inherit;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F2f2f2CC;
    z-index: 2000;
}

.section-loading {
    position: relative;
    min-height: 250px;
}


@media only screen and (max-width: 600px) {
    .header-logo {
        width: 125px !important;
    }

    .accordion-title-text {
        font-size: 0.8rem;
    }
    
    h2, h5{
        font-size: 1.1rem;
    }

    p, div{
        font-size: 0.8rem;
    }

    .banner-dbs,
    .banner-subaru {
        height: 37vw;
        background-position: 15% 50%;
    }
  }


  .hidden {
    display: none;
  }
body {
    font-family: 'Montserrat', sans-serif;
}

.montserrat-normal {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.montserrat-medium{
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.montserrat-bold {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.amex-terms_conditions a{
    text-decoration: underline;
    color: #050033;
    font-weight: 600;
}

/* Additional CSS for AMEX */
.amex-list {
    list-style-type: none;
    line-height: 2;
}

.amex-list li {
    color: #221b1a;
    font-weight: 300;
    font-size: 1rem;
}
.amex-content {
    color: #221b1a;
    font-weight: 300;
    line-height: 1.8;
    font-size: 1rem;
}

.amex-list li:before {
content: '\2014';
position: absolute;
margin-left: -30px;
}

.amex-benefits {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* align-items: center; */
    background-color: #557b97 !important;
    font-weight: 800;
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
}
/* Main Accordion Header Styling */
.amex-benefits-container {
  background: #f9f9f9;
  padding: 25px 25px 5px;
  border-radius: 20px;
}

.benefit-header {
  color: #050033;
  margin-top: 10px;
  font-size: 20px;
}

.benefit-sub {
  color: #050033;
  line-height: 1.8;
  font-size: 1rem;
}

.main-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background-color: #557b97 !important;
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
}

.icons {
  fill: #18a09f;
  width: 20px;
  height: 20px;
}

/* Sub-Accordion Header Styling */
.sub-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f1f1f1;
  color: #050033;
  font-weight: bold;
  cursor: pointer;
  padding: 12px;
}

/* Ensure the header text expands */
.header-text {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  color: #f9f9f9
}

/* Caret icon positioning and transition */
.caret-icon {
  position: absolute;
  margin-left: auto;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  left: 100px
}

/* Rotate caret when accordion is open */
.rotated {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
  
.product-card {
    border-radius: 20px !important;
}
.card {
    border-radius: 20px !important;
}

.explorer-amex-card {
    background-image: url(/static/media/Card-Pullman.88e74eb5.jpg);
    width: 100%;
    height: 175px;
    background-size: cover;
    background-position: 50% 50%;
}

.card-image {
    height: 175px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}
.product-title {
    color: #050033;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 600;
}
.product-subtitle {
    color: #050033;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.product-text {
    color: #050033;
    font-weight: 400;
}
.product-list {
    line-height: 3;
}

.product-list li {
    position: relative; /* Ensure relative positioning for absolute ::before */
    list-style: none; /* Remove default bullet */
}

.product-list li::before {
    content: ""; /* Required for pseudo-elements */
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg' class='icon' fill='%2318A09F'%3E%3Cpath d='M5.703.781a.703.703 0 1 0-1.406 0v3.516H.78a.703.703 0 1 0 0 1.406h3.516V9.22a.703.703 0 1 0 1.406 0V5.703H9.22a.703.703 0 1 0 0-1.406H5.703V.78z'/%3E%3C/svg%3E");
    background-size: contain; /* Ensures the SVG fits properly */
    background-repeat: no-repeat;
    width: 16px; /* Set width */
    height: 16px; /* Set height */
    position: absolute;
    left: -30px; /* Adjust positioning */
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); /* Centers the icon vertically */
}

.carousel-container {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
    overflow-x: hidden;
    width: 100%;
}

.item-card:hover {
    cursor: pointer;
}
.item-card:hover .slide-item-title .item-name {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.item-card:hover .slide-item-title .hover-text {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.slide-item-title {
    position: relative;
    width: 275px;
    color: #050033;
    font-size: 1rem;
    line-height: 1.4285714286rem;
    font-weight: 600;
}

.slide-item-title .item-name {
    position: absolute;
    top: 0%;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}


.pagination-container {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-top: 2rem;
    margin-left: 20rem;
    /* flex-direction: row; */
}

.item-pagination {
    /* margin-top: 2rem; */
    margin-right: 5px;
    border-radius: 20px;
    width: 32px;
    height: 1px;
    padding: 2px;
    background-color: #f0f0f0;
}

.active-pagination {
    background-color: #050033;
}

.img-455,
.img-400-top,
.img-400 {
    object-fit: cover;
    /* transition: transform 0.3s ease-in-out; Smooth transition */
    width: 275px;
}

.img-455 {
    height: 455px;
    /* margin-top: 10px; */
}

.img-400-top {
    height: 400px;
    margin-top: 30px;
}

.img-400 {
    height: 400px;
}

.hover-text {
    opacity: 0;
    position: relative;
    color: #050033;
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    transition: opacity 0.4s ease-in-out;
    /* text-align: center; */
}

.hotel-underline {
    position: absolute;
    height: 2px;
    left: 45%;
    background-color: #050033;
    display: inline-block;
    width: 150px; /* Adjust length of the underline */
}

.restaurant-underline {
    position: absolute;
    height: 2px;
    left: 67%;
    background-color: #050033;
    display: inline-block;
    width: 90px; /* Adjust length of the underline */
}


.slick-list {
    padding-bottom: 20px;
}
