@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.montserrat-normal {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.montserrat-medium{
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.montserrat-bold {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.amex-terms_conditions a{
    text-decoration: underline;
    color: #050033;
    font-weight: 600;
}

/* Additional CSS for AMEX */
.amex-list {
    list-style-type: none;
    line-height: 2;
}

.amex-list li {
    color: #221b1a;
    font-weight: 300;
    font-size: 1rem;
}
.amex-content {
    color: #221b1a;
    font-weight: 300;
    line-height: 1.8;
    font-size: 1rem;
}

.amex-list li:before {
content: '\2014';
position: absolute;
margin-left: -30px;
}

.amex-benefits {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: #557b97 !important;
    font-weight: 800;
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
}