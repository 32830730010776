.product-card {
    border-radius: 20px !important;
}
.card {
    border-radius: 20px !important;
}

.explorer-amex-card {
    background-image: url(../../images/banners/Card-Pullman.jpg);
    width: 100%;
    height: 175px;
    background-size: cover;
    background-position: 50% 50%;
}

.card-image {
    height: 175px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}
.product-title {
    color: #050033;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 600;
}
.product-subtitle {
    color: #050033;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.product-text {
    color: #050033;
    font-weight: 400;
}
.product-list {
    line-height: 3;
}

.product-list li {
    position: relative; /* Ensure relative positioning for absolute ::before */
    list-style: none; /* Remove default bullet */
}

.product-list li::before {
    content: ""; /* Required for pseudo-elements */
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg' class='icon' fill='%2318A09F'%3E%3Cpath d='M5.703.781a.703.703 0 1 0-1.406 0v3.516H.78a.703.703 0 1 0 0 1.406h3.516V9.22a.703.703 0 1 0 1.406 0V5.703H9.22a.703.703 0 1 0 0-1.406H5.703V.78z'/%3E%3C/svg%3E");
    background-size: contain; /* Ensures the SVG fits properly */
    background-repeat: no-repeat;
    width: 16px; /* Set width */
    height: 16px; /* Set height */
    position: absolute;
    left: -30px; /* Adjust positioning */
    top: 50%;
    transform: translateY(-50%); /* Centers the icon vertically */
}
