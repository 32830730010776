.carousel-container {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
    overflow-x: hidden;
    width: 100%;
}

.item-card:hover {
    cursor: pointer;
}
.item-card:hover .slide-item-title .item-name {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.item-card:hover .slide-item-title .hover-text {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.slide-item-title {
    position: relative;
    width: 275px;
    color: #050033;
    font-size: 1rem;
    line-height: 1.4285714286rem;
    font-weight: 600;
}

.slide-item-title .item-name {
    position: absolute;
    top: 0%;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}


.pagination-container {
    display: inline-flex;
    margin-top: 2rem;
    margin-left: 20rem;
    /* flex-direction: row; */
}

.item-pagination {
    /* margin-top: 2rem; */
    margin-right: 5px;
    border-radius: 20px;
    width: 32px;
    height: 1px;
    padding: 2px;
    background-color: #f0f0f0;
}

.active-pagination {
    background-color: #050033;
}

.img-455,
.img-400-top,
.img-400 {
    object-fit: cover;
    /* transition: transform 0.3s ease-in-out; Smooth transition */
    width: 275px;
}

.img-455 {
    height: 455px;
    /* margin-top: 10px; */
}

.img-400-top {
    height: 400px;
    margin-top: 30px;
}

.img-400 {
    height: 400px;
}

.hover-text {
    opacity: 0;
    position: relative;
    color: #050033;
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    transition: opacity 0.4s ease-in-out;
    /* text-align: center; */
}

.hotel-underline {
    position: absolute;
    height: 2px;
    left: 45%;
    background-color: #050033;
    display: inline-block;
    width: 150px; /* Adjust length of the underline */
}

.restaurant-underline {
    position: absolute;
    height: 2px;
    left: 67%;
    background-color: #050033;
    display: inline-block;
    width: 90px; /* Adjust length of the underline */
}


.slick-list {
    padding-bottom: 20px;
}