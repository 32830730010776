@font-face {
    font-family: Montserrat-Light;
    src: url(./fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(./fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(./fonts/Montserrat-SemiBold.ttf);
}

.container{
    max-width: 1280px;
}

p{
    margin-top: 1rem;
}

.header-logo {
    width: 200px !important;
}

.accordion-title-text {
    font-size: .8571428571rem;
    color: #050033;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
}

h2{
    font-weight: 600;
    font-size: 2.1rem;
    color: #050033;
}

.h5, h5 {
    font-size: 1.4285714286rem;
    color: #050033;
    font-weight: 600;
}

h4.benefits-header,
button {
    font-family: Montserrat-Medium,"Verdana",sans-serif;
    color: #050033;
    font-weight: 600;
    font-size: 1.7689rem;
}

.email-check-input {
    display: block;
    margin: 0 auto;
    width: 350px;
    text-align: center;
    font-size: 1rem;
}

.validation-error{
    border: 1px solid #dc3545;
}

.terms_conditions a{
    text-decoration: underline;
    color: #050033;
    font-weight: 600;
}

.terms_conditions p{
    margin: 5px 0;
}

.terms_conditions .title{
    margin-bottom: 20px;
    font-weight: 700;
    color: #74758c;
    font-size: 1.1rem;
}

.hotelSales-wrapper {
    margin-bottom: 10rem;
}

input {
    background-color: #fff;
    border: 1px solid #e6e7e8;
    color: #000;
    padding: 15px 20px;
    line-height: 1.3;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: color .2s .15s,box-shadow .2s;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    border-radius: 2px;
}

.email-check-input-submit {
    display: block;
    margin: 10px auto 0;
    width: 350px;
    font-weight: 600;
}

.button.theme-primary {
    background: #050033;
    border: 3px solid #050033;
    border-radius: 3px;
    color: #fff;
    padding: 15px 20px;
}

p,
div {
    font-family: Montserrat-Light,"Verdana",sans-serif;
    color: #74758c;
    font-size: 1rem;
}

sup,
.disclaimer {
    color: #FF0000;
}

button.btn {
    border: #050033;
    text-transform: uppercase;
    font-size: .875rem;
}

button.btn-primary{
    background: #ec6527;
    border: 3px solid #ec6527;
    border-radius: 3px;
    color: #fff;
}

button.btn-primary:hover {
    color: #fff;
    background: #18a09f;
    border: 3px solid #18a09f;
}

.button.theme-primary .icon {
    fill: #18a09f;
}

.button.theme-primary--alternate .icon, .button.theme-primary:hover .icon {
    fill: #050033;
}


button .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: #f5ac8a;
    border: 3px solid #f5ac8a;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 #fd7e1494;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #f5ac8a;
    border-color: #f5ac8a;
    box-shadow: 0 0 0 0 #fd7e1494;
}

button.btn-secondary {
    background: #fff;
    border: 3px solid #ec6527;
    border-radius: 3px;
    color: #ec6527;
}

button.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: #ec6527;
    border: 3px solid #ec6527;
}

.banner-dbs {
    width: 100%;
    height: 25vw;
    background-image: url(../images/banners/DBS_vantage_card_banner_1650x410_v3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
}

.banner-subaru {
    width: 100%;
    height: 30vw;
    background-image: url(../images/banners/Subaru_banner_1650x1101.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}
.banner-scb {
    width: 100%;
    height: 30vw;
    background-image: url(../images/banners/SC_1650x1101.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 38%;
}
.banner-amex {
    width: 100%;
    height: 30vw;
    background-image: url(../images/banners/AMEX_SG_Pullman.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 46%;
}

.partners-card {
    margin-top: -35px;
}

/**
*     START Accordion Styles
**/
.card {
    margin-bottom: 0;
}
.benefits-header-container, .benefits-container .card {
    border-bottom: 1px solid #e6e7e8 !important;
    box-shadow: none;
}

.benefits-header-container, .benefits-container .card:first-child { 
    border-top: 1px solid #e6e7e8 !important;
    box-shadow: none;
} 

.benefits .card {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #CCCCCC !important;
    border-radius: 0;
    cursor: pointer;
}

.benefits .card h5 {
    text-align: left;
    padding: 0;
}

.benefits .card h5 button {
    padding: 0;
    color: #000000;
    text-decoration: none;
    text-align: left;
}

.benefits .card-header {
    padding: 20px 0;
    border-bottom: none;
    background-color: transparent;
}

.benefits .card-header:hover {
    cursor: pointer;
}

.benefits .card-body {
    padding: 10px 15px;
}

/**
  *     END Accordion Styles
  */

#hotelNameForm {
    height: 300px;
    overflow-y: auto;
}

.error {
    color: #FF0000;
    font-size: 12px;
}

.hotelSearchModalResults {
    height: 350px; 
    overflow-y: auto;
    overflow-x: hidden;
}

.hotelSearchModalResults .table tr{
    border: none;
}

.hotelSearchModalResults .table {
    color: inherit;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F2f2f2CC;
    z-index: 2000;
}

.section-loading {
    position: relative;
    min-height: 250px;
}


@media only screen and (max-width: 600px) {
    .header-logo {
        width: 125px !important;
    }

    .accordion-title-text {
        font-size: 0.8rem;
    }
    
    h2, h5{
        font-size: 1.1rem;
    }

    p, div{
        font-size: 0.8rem;
    }

    .banner-dbs,
    .banner-subaru {
        height: 37vw;
        background-position: 15% 50%;
    }
  }


  .hidden {
    display: none;
  }