/* Main Accordion Header Styling */
.amex-benefits-container {
  background: #f9f9f9;
  padding: 25px 25px 5px;
  border-radius: 20px;
}

.benefit-header {
  color: #050033;
  margin-top: 10px;
  font-size: 20px;
}

.benefit-sub {
  color: #050033;
  line-height: 1.8;
  font-size: 1rem;
}

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #557b97 !important;
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
}

.icons {
  fill: #18a09f;
  width: 20px;
  height: 20px;
}

/* Sub-Accordion Header Styling */
.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  color: #050033;
  font-weight: bold;
  cursor: pointer;
  padding: 12px;
}

/* Ensure the header text expands */
.header-text {
  flex-grow: 1;
  color: #f9f9f9
}

/* Caret icon positioning and transition */
.caret-icon {
  position: absolute;
  margin-left: auto;
  transition: transform 0.3s ease-in-out;
  left: 100px
}

/* Rotate caret when accordion is open */
.rotated {
  transform: rotate(180deg);
}
  